export default {
  name: "SubadminDetailComponent",
  data() {
    return {
      tabs: null,
      screenHeight: 0,
      texts: "",
      subadminDetailArr: [],
    };
  },
  beforeMount() {
    this.texts = FILE.subadminTexts[this.selectLanguage];
    this.subadminDetail();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenHeight = window.innerHeight - 240;
    },
    subadminDetail() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$route.params.id}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            iPageNumber: 1,
          },
        }
      )
        .then((response) => {
          this.subadminDetailArr = response.data.results;
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },

  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.subadminTexts[this.selectLanguage];
      }
    },
  },
};